import React from "react";

import { ServiceSvgProps } from "pages/services/types/serviceSvgProps";
import { getServiceSvgStyles } from "pages/services/utils/getServiceSvgStyles";

export const VisualIdentitySvg = ({ useAltColor }: ServiceSvgProps) => {
  const { colorOneStyles, colorTwoStyles } = getServiceSvgStyles(useAltColor);

  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 82">
      <g id="iv-logo">
        <g>
          <path
            id="second-color"
            className={colorTwoStyles}
            d="M23.39,9.17h40.77V74.38c0,2.21-1.79,4-4,4H27.39c-2.21,0-4-1.79-4-4V9.17h0Z"
            transform="translate(-18.13 43.77) rotate(-45)"
          />
          <g id="main-color">
            <path
              className={colorOneStyles}
              d="M31.48,2.66c-.77-.78-1.89-1.09-2.95-.84l-9.95,2.35-9.77,2.3c-1.16,.28-2.07,1.18-2.34,2.35l-2.31,9.76L1.81,28.53c-.25,1.07,.07,2.18,.84,2.96l2.23,2.23L33.71,4.89l-2.23-2.23Zm-14.64,14.18c-1.38,1.38-3.61,1.38-4.99,0s-1.38-3.61,0-4.99c1.38-1.38,3.61-1.38,4.99,0,1.37,1.38,1.37,3.61,0,4.99Z"
            />
            <path
              className={colorOneStyles}
              d="M80.43,51.78l-11.33-11c-6.74-1.19-15.21-.67-25.44,3.45-10.48,4.23-19.09,4.24-25.75,2.62l34.31,33.47,3.56,.39,24.88-24.88-.23-4.04Z"
            />
          </g>
          <path
            id="stroke"
            d="M80.54,50.29l-10.45-10.45v-.05s-.04,0-.06-.01L35.13,4.89h0l-2.94-2.94c-1.03-1.03-2.48-1.44-3.89-1.11L8.59,5.5c-.43,.1-.82,.27-1.19,.49L1.71,.29C1.32-.1,.68-.1,.29,.29S-.1,1.32,.29,1.71L5.99,7.4c-.22,.36-.39,.76-.49,1.19L.85,28.3c-.33,1.39,.09,2.88,1.1,3.89l2.85,2.85,45.49,45.49c.97,.97,2.25,1.46,3.54,1.46s2.56-.49,3.54-1.46l23.17-23.17c.94-.94,1.46-2.2,1.46-3.54s-.52-2.59-1.46-3.54ZM2.79,28.76L7.45,9.05s.04-.09,.05-.13l2.84,2.84c.06,.06,.14,.1,.21,.14-1.13,1.75-.94,4.12,.6,5.66,.86,.86,1.99,1.33,3.2,1.33s2.35-.47,3.2-1.33c1.77-1.77,1.77-4.64,0-6.4-.85-.86-1.99-1.33-3.2-1.33-.89,0-1.73,.26-2.46,.73-.04-.07-.08-.15-.14-.21l-2.84-2.84s.09-.04,.13-.05l9.77-2.31h0l9.95-2.35c.73-.17,1.48,.04,2.02,.57l1.52,1.52L4.89,32.3l-1.52-1.52c-.52-.52-.74-1.3-.57-2.02ZM12.56,12.56c.48-.48,1.11-.74,1.79-.74s1.31,.26,1.79,.74c.99,.99,.99,2.59,0,3.58s-2.62,.96-3.58,0-.99-2.59,0-3.58Zm21.16-6.26l33.07,33.07c-7.19-.62-14.93,.7-23.08,3.93-8.91,3.54-17.37,4.43-25.16,2.66l-12.25-12.25L33.72,6.3Zm45.4,49.65l-23.17,23.17c-1.17,1.17-3.07,1.17-4.24,0l-30.67-30.67c1.8,.26,3.63,.41,5.49,.41,5.74,0,11.73-1.23,17.92-3.69,8.8-3.49,17.08-4.66,24.63-3.5l10.04,10.04c.57,.57,.88,1.32,.88,2.12s-.31,1.55-.88,2.12Z"
          />
        </g>
      </g>
    </svg>
  );
};
