import React from "react";

import { getServiceSvgStyles } from "pages/services/utils/getServiceSvgStyles";

type Props = {
  useAltColor: boolean;
};

export const WebDesignSvg = ({ useAltColor }: Props) => {
  const { colorOneStyles, colorTwoStyles } = getServiceSvgStyles(useAltColor);

  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81.1 82">
      <g id="iv-logo">
        <g id="web-design">
          <g id="second-color">
            <polygon
              className={colorOneStyles}
              points="80.27 15.77 80.1 4 77.11 1 3.99 1 1 4 1.27 15.77 80.27 15.77"
            />
            <path
              className={colorOneStyles}
              d="M40.79,35.78c-16.18,6.1-29.48,6.12-39.77,3.78l-.02,14.73,2.99,3H77.11l2.99-3-.02-23.48c-10.4-1.72-23.49-.97-39.29,4.97Z"
            />
          </g>
          <g id="main-color">
            <path
              className={colorTwoStyles}
              d="M1,15.77v23.23s18.28,6.54,39.79-3.22c19.16-8.7,39.29-4.97,39.29-4.97l.02-15.03H1Z"
            />
            <path
              className={colorTwoStyles}
              d="M10.75,10.15c.97-.98,.97-2.56,0-3.54-.97-.98-2.53-.98-3.5,0-.97,.98-.97,2.56,0,3.54s2.53,.98,3.5,0Z"
            />
            <path
              className={colorTwoStyles}
              d="M20.92,10.15c.97-.98,.97-2.56,0-3.54-.97-.98-2.53-.98-3.5,0-.97,.98-.97,2.56,0,3.54s2.53,.98,3.5,0Z"
            />
            <path
              className={colorTwoStyles}
              d="M31.09,10.15c.97-.98,.97-2.56,0-3.54-.97-.98-2.53-.98-3.5,0-.97,.98-.97,2.56,0,3.54s2.53,.98,3.5,0Z"
            />
            <path
              className={colorTwoStyles}
              d="M55.08,75h-8.75l-1.85-17.71h-7.86l-1.85,17.71h-8.75c-1.1,0-2,.9-2,2v2c0,1.1,.9,2,2,2h29.07c1.1,0,2-.9,2-2v-2c0-1.1-.9-2-2-2Z"
            />
          </g>
          <g id="stroke">
            <path d="M76.1,0H5C2.24,0,0,2.24,0,5V53.29c0,2.76,2.24,5,5,5h30.51l-1.64,15.71h-7.85c-1.65,0-3,1.35-3,3v2c0,1.65,1.35,3,3,3h29.07c1.65,0,3-1.35,3-3v-2c0-1.65-1.35-3-3-3h-7.85l-1.64-15.71h30.51c2.76,0,5-2.24,5-5V5c0-2.76-2.24-5-5-5Zm-28.66,76h7.64c.55,0,1,.45,1,1v2c0,.55-.45,1-1,1H26.02c-.55,0-1-.45-1-1v-2c0-.55,.45-1,1-1h21.43Zm-11.57-2l1.64-15.71h6.06l1.64,15.71h-9.35Zm43.22-20.71c0,1.65-1.35,3-3,3H5c-1.65,0-3-1.35-3-3v-12.52c3.94,.81,7.98,1.23,12.11,1.23,8.65,0,17.69-1.77,27.03-5.29,13.56-5.1,26.32-6.79,37.95-5.04v21.62Zm0-23.64c-11.89-1.73-24.89,.01-38.66,5.19-13.6,5.13-26.53,6.43-38.44,3.89V16.77H79.1v12.88Zm0-14.88H2V5c0-1.65,1.35-3,3-3H76.1c1.65,0,3,1.35,3,3V14.77Z" />
            <path d="M11.46,5.92c-1.31-1.33-3.6-1.33-4.92,0-1.35,1.36-1.35,3.58,0,4.94,.66,.66,1.53,1.03,2.46,1.03s1.8-.37,2.46-1.03c1.35-1.36,1.35-3.58,0-4.94h0Zm-1.42,3.54c-.55,.56-1.52,.56-2.07,0-.58-.59-.58-1.54,0-2.13,.28-.28,.65-.44,1.04-.44s.76,.15,1.04,.44c.58,.59,.58,1.54,0,2.13Z" />
            <path d="M21.63,5.92c-1.31-1.33-3.61-1.33-4.92,0-1.35,1.36-1.35,3.58,0,4.94,.66,.66,1.53,1.03,2.46,1.03s1.8-.37,2.46-1.03c1.35-1.36,1.35-3.58,0-4.94h0Zm-1.42,3.54c-.55,.56-1.52,.56-2.07,0-.58-.59-.58-1.54,0-2.13,.28-.28,.65-.44,1.04-.44s.76,.15,1.04,.44c.58,.59,.58,1.54,0,2.13Z" />
            <path d="M31.8,5.92c-1.31-1.33-3.6-1.33-4.92,0-1.35,1.36-1.35,3.58,0,4.94,.66,.66,1.53,1.03,2.46,1.03s1.8-.37,2.46-1.03c1.35-1.36,1.35-3.58,0-4.94h0Zm-1.42,3.54c-.55,.56-1.52,.56-2.07,0-.58-.59-.58-1.54,0-2.13,.28-.28,.65-.44,1.04-.44s.76,.15,1.04,.44c.58,.59,.58,1.54,0,2.13Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};
